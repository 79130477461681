<template>
  <b-col xl="6" lg="6" md="6" sm="12" cols="12" class="priority-channel">
    <b-form-tags
      id="tags-component-select"
      v-model="value"
      size="lg"
      class="mb-2"
      add-on-change
      no-outer-focus
    >
      <template
        v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }"
      >
        <b-input-group size="sm" :prepend="title">
          <b-form-tag
            v-for="tag in tags"
            :key="tag"
            @remove="removeTag(tag)"
            :title="tag"
            :disabled="disabled"
            variant="info"
            >{{ tag.toUpperCase() }}</b-form-tag
          >

          <b-form-select
            v-bind="inputAttrs"
            v-on="inputHandlers"
            :disabled="disabled || availableOptions.length === 0"
            :options="availableOptions"
          >
            <template #first>
              <!-- This is required to prevent bugs with Safari -->
              <option disabled value="">...</option>
            </template>
          </b-form-select>
        </b-input-group>
      </template>
    </b-form-tags>
  </b-col>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "config-priority-channel",
  props: {
    title: {
      type: String,
      default: "",
      description: ""
    },
    shortTitle: {
      type: String,
      default: "",
      description: ""
    },
    options: {
      type: Array,
      default: () => [],
      description: ""
    }
    // value: {
    //   type: Array,
    //   default: () => [],
    //   description: ''
    // },
  },
  // watch:{
  //     value: function(newVal){
  //         if(this.isValueChange(newVal)){
  //             let vm = this;
  //             let data = {};
  //             data[this.shortTitle] = newVal.map(function(e){ return vm.channelDict[e]; });
  //             this.$emit("tag-change", data);
  //         }
  //     }
  // },
  data() {
    return {
      value: [],
      originValue: [],
      channelDict: {}
    };
  },
  computed: {
    ...mapGetters(["channel"]),
    availableOptions() {
      return this.originValue.filter(opt => this.value.indexOf(opt) === -1);
    }
  },
  mounted() {
    let vm = this;
    this.value = this.options.map(function(e) {
      return vm.channel[e][0].code;
    });
    this.originValue = this.options.map(function(e) {
      return vm.channel[e][0].code;
    });

    let channelDict = {};
    for (let i = 0; i < this.options.length; i++) {
      channelDict[vm.channel[this.options[i]][0].code] = this.options[i];
    }
    this.channelDict = channelDict;
  },
  methods: {
    isValidChange(newVal = null) {
      if (!newVal) newVal = this.value;
      return this.originValue.length == newVal.length;
    },
    getValue() {
      if (!this.isValidChange()) return null;

      let vm = this;
      let data = {};
      data[this.shortTitle] = this.value.map(function(e) {
        return vm.channelDict[e];
      });
      return data;
    }
  }
};
</script>
<style>
.priority-channel .b-form-tag {
  margin-right: 0.1rem;
}
.priority-channel .input-group-prepend {
  margin-right: 3px;
}

.priority-channel .input-group-text {
  width: 65px;
}
#tags-component-select {
  padding: 0;
}
</style>
