<template>
  <b-col xl="6" lg="6" md="6" sm="12" cols="12">
    <b-input-group class="mb-2 config-com-rate" size="sm">
      <div class="input-group-prepend">
        <span class="input-group-text text-sub">{{ title }}</span>
      </div>
      <b-input-group-prepend class="comm-label" is-text>
        <b>Cò:</b>
      </b-input-group-prepend>
      <b-form-input
        :class="
          highlightMultiCommission ? 'comm-mul-mark commission' : 'commission'
        "
        :value="formatComm(commission)"
        :origin="commission"
        :game="game"
        :prop="prop"
      ></b-form-input>
      <b-input-group-prepend class="rate-label" is-text>
        <b>Trúng:</b>
      </b-input-group-prepend>
      <b-form-input
        class="rate"
        :value="rate"
        :origin="rate"
        :game="game"
        :prop="prop"
      ></b-form-input>
    </b-input-group>
  </b-col>
</template>
<script>
var numeral = require("numeral");

export default {
  name: "config-com-rate",
  props: {
    title: {
      type: String,
      default: "",
      description: ""
    },
    commission: {
      type: Number,
      default: 0,
      description: ""
    },
    rate: {
      type: Number,
      default: 0,
      description: ""
    },
    prop: {
      type: Number
    },
    game: {
      type: Number
    },
    highlightMultiCommission: {
      type: Boolean
    }
  },
  methods: {
    formatComm(comm) {
      let commission = parseFloat(comm);
      commission = numeral(commission).format("0,0.00");
      return commission;
    }
  }
};
</script>
<style>
.config-com-rate .input-group-text {
  width: 75px;
}
.config-com-rate .comm-label {
  width: 40px;
}
.config-com-rate .rate-label {
  width: 60px;
}
.comm-mul-mark {
  background-color: #ffffea;
}
</style>
