<template>
  <b-container class="customer-detail">
    <div class="px-3">
      <b-row align-v="center" class="mt-2 mb-2">
        <b-col sm="6" cols="2">
          <button-back link="/customers"></button-back>
        </b-col>
        <b-col sm="4" cols="7" class="text-right">
          <b-form-input
            v-model="name"
            type="text"
            placeholder="Nhập tên khách hàng"
            required
          ></b-form-input>
        </b-col>
        <b-col sm="2" cols="2">
          <b-button @click="save" variant="primary" size="sm"> Lưu </b-button>
        </b-col>
      </b-row>
      <hr class="my-2" />
    </div>
    <div class="content pt-3">
      <b-row class="m-0">
        <b-col lg="6" md="6" sm="12" cols="12">
          <b-input-group class="mb-2" size="sm">
            <div class="input-group-prepend title">
              <span class="input-group-text text-sub">Cú pháp:</span>
            </div>
            <b-form-select
              v-model="parse_selected"
              :options="parse_opt"
            ></b-form-select>
            <b-input-group-append>
              <b-button variant="primary">Copy cho tất cả</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col lg="3" md="3" sm="6" cols="12">
          <b-input-group class="mb-2" size="sm">
            <div class="input-group-prepend title">
              <span class="input-group-text text-sub">% theo:</span>
            </div>
            <b-form-input v-model="percent"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col lg="6" md="6" sm="6" cols="12">
          <b-input-group class="mb-2" size="sm">
            <div class="input-group-prepend title">
              <span class="input-group-text text-sub">KI rưỡi:</span>
            </div>
            <b-input-group-append is-text class="content">
              <b-form-checkbox class="pr-1" v-model="ki_ruoi[0]"
                >Bắc</b-form-checkbox
              >
              <b-form-checkbox class="pr-1" v-model="ki_ruoi[1]"
                >Trung</b-form-checkbox
              >
              <b-form-checkbox v-model="ki_ruoi[2]">Nam</b-form-checkbox>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col lg="6" md="6" sm="6" cols="12">
          <b-input-group class="mb-2" size="sm">
            <div class="input-group-prepend title">
              <span class="input-group-text text-sub">Giao Nhận:</span>
            </div>
            <b-input-group-append is-text class="content">
              <b-form-radio class="pr-2" v-model="giao_nhan" value="1"
                >Giao</b-form-radio
              >
              <b-form-radio v-model="giao_nhan" value="0">Nhận</b-form-radio>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col lg="3" md="3" sm="6" cols="6">
          <b-input-group class="mb-2" size="sm">
            <div class="input-group-prepend title-cols">
              <span class="input-group-text text-sub">Cảnh báo:</span>
            </div>
            <b-input-group-append is-text class="content-cols">
              <b-form-checkbox v-model="canh_bao"></b-form-checkbox>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col lg="3" md="3" sm="6" cols="6">
          <b-input-group class="mb-2" size="sm">
            <div class="input-group-prepend title-cols">
              <span class="input-group-text text-sub">Cò đã nhân:</span>
            </div>
            <b-input-group-append is-text class="content-cols">
              <b-form-checkbox
                :disabled="!!$route.params.playerId"
                v-model="da_nhan"
              ></b-form-checkbox>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <div class="px-3">
        <h3>Miền Bắc</h3>
        <b-row class="mb-config">
          <config-com-rate
            :title="item.title"
            :key="indexItem"
            :commission="item.commission"
            :rate="item.rate"
            :prop="item.prop_id"
            :game="item.game_id"
            :highlightMultiCommission="
              da_nhan === true && [4, 9, 14].indexOf(item.prop_id) >= 0
            "
            v-for="(item, indexItem) in configMb"
          >
          </config-com-rate>
        </b-row>
        <hr class="my-2" />
        <h3>Miền Trung</h3>
        <b-row class="mt-config">
          <config-com-rate
            :title="item.title"
            :key="indexItem"
            :commission="item.commission"
            :rate="item.rate"
            :prop="item.prop_id"
            :game="item.game_id"
            :highlightMultiCommission="
              da_nhan === true && [5, 10, 14, 17].indexOf(item.prop_id) >= 0
            "
            v-for="(item, indexItem) in configMt"
          >
          </config-com-rate>
        </b-row>
        <hr class="my-2" />
        <h3>Miền Nam</h3>
        <b-row class="mn-config">
          <config-com-rate
            :title="item.title"
            :key="indexItem"
            :commission="item.commission"
            :rate="item.rate"
            :prop="item.prop_id"
            :game="item.game_id"
            :highlightMultiCommission="
              da_nhan === true && [5, 10, 14, 17].indexOf(item.prop_id) >= 0
            "
            v-for="(item, indexItem) in configMn"
          >
          </config-com-rate>
        </b-row>
        <h3>Chọn thứ tự đài MN</h3>
        <div class="text-center text-info mb-2">
          <small>Cú pháp dc,dp nhận theo thứ tự bạn chọn</small>
        </div>
        <b-row class="priority-channel">
          <config-priority-channel
            :title="item.title"
            :shortTitle="item.shortTitle"
            :options="item.options"
            :value="item.value"
            :key="indexItem"
            :ref="'priorityChannel_' + indexItem"
            v-for="(item, indexItem) in configPriorityChannel"
          >
            >
          </config-priority-channel>
        </b-row>
      </div>
      <div class="ml-3 mr-3">
        <b-button @click="save" block variant="primary">Lưu</b-button>
      </div>
      <div class="empty-block"></div>
    </div>
  </b-container>
</template>
<script>
import ConfigComRate from "@/components/customer/ConfigComRate";
import ConfigPriorityChannel from "@/components/customer/ConfigPriorityChannel";
import { mapGetters } from "vuex";
import { groupBy } from "lodash";

export default {
  name: "CustomerDetail",
  data() {
    return {
      nameOld: "",
      name: "",
      originSetting: {},
      parse_selected: 0,
      parse_opt: [],
      giao_nhan: 0,
      ki_ruoi: [false, false, false],
      canh_bao: false,
      percent: 0,
      da_nhan: false,
      configMb: [],
      configMt: [],
      configMn: [],
      configPriorityChannel: []
    };
  },
  components: {
    ConfigComRate,
    ConfigPriorityChannel
  },
  computed: {
    ...mapGetters(["parseTypes", "prop"])
  },
  watch: {
    da_nhan: function(newVal) {
      let playerId = this.$route.params.playerId;
      if (playerId) return;
      let mb,
        mt,
        mn = [];

      if (newVal == false) {
        mb = this.configMb.map(function(e) {
          let com = e.commission;
          e.commission = e.commission_m;
          e.commission_m = com;
          return e;
        });
        mt = this.configMt.map(function(e) {
          let com = e.commission;
          e.commission = e.commission_m;
          e.commission_m = com;
          return e;
        });
        mn = this.configMn.map(function(e) {
          let com = e.commission;
          e.commission = e.commission_m;
          e.commission_m = com;
          return e;
        });
      } else {
        mb = this.configMb.map(function(e) {
          let com = e.commission_m;
          e.commission_m = e.commission;
          e.commission = com;
          return e;
        });
        mt = this.configMt.map(function(e) {
          let com = e.commission_m;
          e.commission_m = e.commission;
          e.commission = com;
          return e;
        });
        mn = this.configMn.map(function(e) {
          let com = e.commission_m;
          e.commission_m = e.commission;
          e.commission = com;
          return e;
        });
      }

      this.configMb = mb;
      this.configMt = mt;
      this.configMn = mn;
    }
  },
  created() {
    this.tryFetchPlayerDetail();
    this.fetchPlayerGameConfig();
    this.initParseType();
  },
  methods: {
    initParseType() {
      this.parse_opt = this.parseTypes.map(function(e) {
        return { value: e.id, text: e.name };
      });
      if (this.parseTypes.length > 0) {
        this.parse_selected = this.parseTypes[0].id;
      }
    },
    async tryFetchPlayerDetail() {
      let vm = this;
      let playerId = vm.$route.params.playerId;
      if (!playerId) return;

      let res = await vm.$http.get("/player/" + playerId + "/setting");
      vm.nameOld = res.data.data.name;
      vm.name = res.data.data.name;
      vm.parse_selected = res.data.data.parse;
      vm.giao_nhan = res.data.data.type;
      vm.ki_ruoi = vm.kiToArray(res.data.data.ki);
      vm.canh_bao = res.data.data.warning == 0 ? false : true;
      vm.percent = res.data.data.percent;
      vm.da_nhan = res.data.data.commission_type == 0 ? false : true;
      vm.originSetting = res.data.data;
    },
    async fetchPlayerGameConfig() {
      let vm = this;
      let playerId = vm.$route.params.playerId;

      let res = await vm.$http.get(
        playerId ? "/player/" + playerId + "/config" : "/player/config"
      );
      vm.configMb = res.data.data.gameConfig["1"].map(e => {
        return {
          title: vm.prop[e.prop_id][0].alias,
          commission: e.commission,
          commission_m: e.commission_m,
          rate: e.rate,
          game_id: e.game_id,
          prop_id: e.prop_id
        };
      });
      vm.configMt = res.data.data.gameConfig["2"].map(e => {
        return {
          title: vm.prop[e.prop_id][0].alias,
          commission: e.commission,
          commission_m: e.commission_m,
          rate: e.rate,
          game_id: e.game_id,
          prop_id: e.prop_id
        };
      });
      vm.configMn = res.data.data.gameConfig["3"].map(e => {
        return {
          title: vm.prop[e.prop_id][0].alias,
          commission: e.commission,
          commission_m: e.commission_m,
          rate: e.rate,
          game_id: e.game_id,
          prop_id: e.prop_id
        };
      });
      vm.configPriorityChannel = res.data.data.priorityChannel;
    },
    async save() {
      let playerId = this.$route.params.playerId;
      if (!playerId) {
        playerId = await this.createPlayer();
        if (playerId === false) return;
      }

      if (this.nameOld != this.name) {
        let res = await this.$http.put("/player/" + playerId, {
          playerName: this.name
        });
        if (res.data.code == 0) {
          this.makeToast(
            "Thành công",
            "Thay đổi tên khách hàng thành công",
            "success",
            true
          );
        }
      }

      await this.tryToSaveSetting(playerId);
      let cfgSuccess = await this.tryToSaveConfig(playerId);

      if (!cfgSuccess) return;

      let vm = this;
      vm.$store.dispatch("getPlayers");
      setTimeout(() => {
        vm.$router.push({ name: "customers" });
      }, 2000);
    },
    async createPlayer() {
      let res = await this.$http.post("/player", { playerName: this.name });
      if (res.data.code == 0) {
        this.makeToast(
          "Thành công",
          `Tạo khách ${this.name} thành công`,
          "success"
        );
        this.$store.dispatch("getPlayers");
        return res.data.data.id;
      } else {
        this.makeToast(
          `Có lỗi xảy ra khi tạo khách ${this.name}`,
          res.data.msg,
          "danger"
        );
      }

      return false;
    },
    async updatePlayer() {},
    async tryToSaveSetting(playerId) {
      if (
        this.originSetting.ki == this.arrayToKi(this.ki_ruoi) &&
        this.originSetting.parse == this.parse_selected &&
        this.originSetting.warning == this.canh_bao &&
        this.originSetting.percent == this.percent &&
        this.originSetting.type == this.giao_nhan &&
        this.originSetting.commission_type == this.da_nhan
      ) {
        return new Promise(resolve => {
          resolve(false);
        });
      }

      let data = {
        setting: {
          ki: this.arrayToKi(this.ki_ruoi),
          parse: this.parse_selected,
          warning: this.canh_bao,
          percent: this.percent,
          type: this.giao_nhan,
          commission_type: this.da_nhan == true ? 1 : 0
        }
      };

      let res = await this.$http.post("/player/" + playerId + "/setting", data);
      if (res.data.code == 0) {
        this.makeToast(
          "Thành công",
          "Lưu thông tin thành công",
          "success",
          true
        );
      }
    },
    validateCommission(comms) {
      let invalid = comms.filter(function(c) {
        let match = ("" + c.commission).match(/^\d+\.?\d{0,2}/);
        if (!match) return false;
        return c.commission != match[0];
      });
      return invalid
        .map(function(c) {
          return c.commission;
        })
        .join();
    },
    async tryToSaveConfig(playerId) {
      playerId;
      let mb = this.getChangedConfig(".mb-config");
      let mt = this.getChangedConfig(".mt-config");
      let mn = this.getChangedConfig(".mn-config");

      let invalid = [
        this.validateCommission(mb),
        this.validateCommission(mt),
        this.validateCommission(mn)
      ].join();
      if (invalid != ",,") {
        this.makeToast(
          "Lỗi",
          "Giá trị cò " + invalid + " không hợp lệ",
          "danger",
          true
        );
        return new Promise(resolve => {
          resolve(false);
        });
      }

      let priorityChannel = this.getPriorityChannelChange();
      if (priorityChannel.filter(e => e == null).length > 0) {
        this.makeToast(
          "Lỗi",
          "Thứ tự đài không hợp lệ, vui lòng kiểm tra lại!",
          "danger",
          true
        );
        return new Promise(resolve => {
          resolve(false);
        });
      }
      let data = {
        config: mb.concat(mt, mn),
        priorityChannel: this.getPriorityChannelChange()
      };

      if (data.config.length == 0 && data.priorityChannel.length == 0) {
        return new Promise(resolve => {
          resolve(true);
        });
      }

      let res = await this.$http.post("/player/" + playerId + "/config", data);
      if (res.data.code == 0) {
        this.makeToast(
          "Thành công",
          "Lưu thông số cấu hình game thành công",
          "success",
          true
        );
      }

      return new Promise(resolve => {
        resolve(true);
      });
    },
    getPriorityChannelChange() {
      let vm = this;
      let changed = this.configPriorityChannel.map(function(e, idx) {
        return vm.$refs["priorityChannel_" + idx][0].getValue();
      });

      return changed;
    },
    getChangedConfig(selector) {
      let filter = Array.prototype.filter;
      let comms = filter
        .call(document.querySelectorAll(selector + " .commission"), function(
          e
        ) {
          let origin = parseFloat(e.getAttribute("origin"));
          let current = parseFloat(e.value);
          return origin != current;
        })
        .map(e => {
          return {
            game_id: parseInt(e.getAttribute("game")),
            prop_id: parseInt(e.getAttribute("prop")),
            commission: parseFloat(e.value)
          };
        });

      let rates = filter
        .call(document.querySelectorAll(selector + " .rate"), function(e) {
          let origin = e.getAttribute("origin");
          let current = e.value;
          return origin != current;
        })
        .map(e => {
          return {
            game_id: parseInt(e.getAttribute("game")),
            prop_id: parseInt(e.getAttribute("prop")),
            rate: parseFloat(e.value)
          };
        });

      let data = [];
      let gComms = groupBy(comms, e => {
        return e.game_id + "_" + e.prop_id;
      });
      let gRates = groupBy(rates, e => {
        return e.game_id + "_" + e.prop_id;
      });
      for (let key in gComms) {
        if (gRates[key]) {
          gComms[key][0].rate = gRates[key][0].rate;
        }
        data.push(gComms[key][0]);
      }

      for (let key in gRates) {
        if (gComms[key]) continue;
        data.push(gRates[key][0]);
      }

      return data;
    },
    makeToast(title, content, variant, append = false) {
      this.$bvToast.toast(content, {
        title: title,
        autoHideDelay: 5000,
        appendToast: append,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-right mb-5"
      });
    },
    kiToArray(ki) {
      let kiArr = ki.split("");
      return kiArr.map(e => {
        return e === "1" ? true : false;
      });
    },
    arrayToKi(arr) {
      return arr
        .map(e => {
          return e === true ? "1" : "0";
        })
        .join("");
    }
  }
};
</script>
<style>
.card-body {
  padding: 0.5rem;
}
.custom-control {
  padding-left: 1.5rem;
}
.custom-control-label::after,
.custom-control-label::before {
  left: -1.5rem;
}
.col-full {
  padding: 0px;
}
.input-group .form-control:not(:first-child) {
  border-left: -1px;
}
.empty-block {
  height: env(safe-area-inset-bottom, 0);
}
.input-group-prepend.title-cols {
  width: 50%;
  display: grid;
}
.input-group-prepend.title {
  width: 23%;
  display: grid;
}
.input-group-append.content-cols {
  width: 50%;
  display: grid;
}
.input-group-append.content {
  width: 77%;
  display: grid;
}
</style>
